<template>
  <span class="flex items-center gap-md">
    <input
      :id="name"
      v-bind="$attrs"
      type="checkbox"
      class="size-4 rounded-xs border border-gray-300 text-brand-600 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-brand-500"
      :value="value"
      :checked="checked"
      @change="handleChange"
    >
    <UiLabel
      v-if="$slots.default"
      :for="name"
    >
      <slot />
    </UiLabel>
  </span>
</template>

<script lang="ts" setup>
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    name: string
    value?: string
  }>(),
  {
    value: 'on',
  },
)

const { checked, handleChange } = useField(() => props.name, undefined, {
  type: 'checkbox',
  checkedValue: props.value,
})
</script>
